exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-bedrijf-wiki-js": () => import("./../../../src/templates/bedrijfWiki.js" /* webpackChunkName: "component---src-templates-bedrijf-wiki-js" */),
  "component---src-templates-brand-categories-js": () => import("./../../../src/templates/brandCategories.js" /* webpackChunkName: "component---src-templates-brand-categories-js" */),
  "component---src-templates-brand-category-single-js": () => import("./../../../src/templates/brandCategorySingle.js" /* webpackChunkName: "component---src-templates-brand-category-single-js" */),
  "component---src-templates-brand-single-js": () => import("./../../../src/templates/brandSingle.js" /* webpackChunkName: "component---src-templates-brand-single-js" */),
  "component---src-templates-brewery-js": () => import("./../../../src/templates/brewery.js" /* webpackChunkName: "component---src-templates-brewery-js" */),
  "component---src-templates-brewery-process-js": () => import("./../../../src/templates/breweryProcess.js" /* webpackChunkName: "component---src-templates-brewery-process-js" */),
  "component---src-templates-brewery-visit-js": () => import("./../../../src/templates/breweryVisit.js" /* webpackChunkName: "component---src-templates-brewery-visit-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-disclaimer-js": () => import("./../../../src/templates/disclaimer.js" /* webpackChunkName: "component---src-templates-disclaimer-js" */),
  "component---src-templates-event-single-js": () => import("./../../../src/templates/eventSingle.js" /* webpackChunkName: "component---src-templates-event-single-js" */),
  "component---src-templates-history-js": () => import("./../../../src/templates/history.js" /* webpackChunkName: "component---src-templates-history-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-horeca-js": () => import("./../../../src/templates/horeca.js" /* webpackChunkName: "component---src-templates-horeca-js" */),
  "component---src-templates-horeca-wiki-js": () => import("./../../../src/templates/horecaWiki.js" /* webpackChunkName: "component---src-templates-horeca-wiki-js" */),
  "component---src-templates-immo-js": () => import("./../../../src/templates/immo.js" /* webpackChunkName: "component---src-templates-immo-js" */),
  "component---src-templates-immo-single-js": () => import("./../../../src/templates/immoSingle.js" /* webpackChunkName: "component---src-templates-immo-single-js" */),
  "component---src-templates-jobs-js": () => import("./../../../src/templates/jobs.js" /* webpackChunkName: "component---src-templates-jobs-js" */),
  "component---src-templates-jobs-single-js": () => import("./../../../src/templates/jobsSingle.js" /* webpackChunkName: "component---src-templates-jobs-single-js" */),
  "component---src-templates-logos-js": () => import("./../../../src/templates/logos.js" /* webpackChunkName: "component---src-templates-logos-js" */),
  "component---src-templates-news-js": () => import("./../../../src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-templates-news-single-js": () => import("./../../../src/templates/newsSingle.js" /* webpackChunkName: "component---src-templates-news-single-js" */),
  "component---src-templates-selling-points-js": () => import("./../../../src/templates/sellingPoints.js" /* webpackChunkName: "component---src-templates-selling-points-js" */),
  "component---src-templates-single-press-js": () => import("./../../../src/templates/singlePress.js" /* webpackChunkName: "component---src-templates-single-press-js" */),
  "component---src-templates-single-story-js": () => import("./../../../src/templates/singleStory.js" /* webpackChunkName: "component---src-templates-single-story-js" */),
  "component---src-templates-sitemap-js": () => import("./../../../src/templates/sitemap.js" /* webpackChunkName: "component---src-templates-sitemap-js" */)
}

